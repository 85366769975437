.Contact-container{
    background-image: url("../../assets/woman-working-while-sitting-sofa\ 1.svg");
    background-repeat: no-repeat;
    background-position: 50% center;
    background-size: cover;
    padding: 100px 0;
    z-index: -9999;
  
}
.error-message{
    font-size: 13px;
    font-weight: 400;
    color: red;
}

 @media (max-width: 992px){
    .Contact-container{
        background-position: 35% center; 
     }
} 

.contact-heading{
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 20px;
    margin-top: 30px;
    color: #000;
}
.Contact-container form {
   
    padding: 32px 64px 28px 45px;
    background-color: white;
    border-radius: 6px;
  
    margin-right: 0; 
 
}

.Contact-container form h2 {
    font-size: 28px;
    font-weight: 600;
    text-align: center;
    color: #000;
}

.Contact-container form span {
    font-size: 18px;
    font-weight: 600;
    color: #000;
}

.Contact-container form label {
    font-size: 18px;
    font-weight: 600;
    color: #000;
    margin-bottom: 0px;
}

.react-tel-input .form-control {
    width: 100%;
}

.react-tel-input .country-list .country-name ,
.react-tel-input .country-list .country .dial-code{
    font-size: 16px;
}
.Contact-container form .btn-primary {
    margin-top: 0px; 
    margin-bottom: 30px; 
    width:35% ;
    border-radius: 6px;
    background-color: black !important;
    border: none;
}
/* Add these styles to your Contact.css or a separate CSS file */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}
.form-outline {
    margin: 0 0 22px;
}
textarea.form-control {
    height: 71px!important;
}
.popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 6px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
}
.custom-button{
    width: 35% !important;
  
}
.breadcrumbs {
    margin-top: 20px!important;
    margin-bottom: 18px!important;
}
@media (max-width: 1200px) and (min-width : 768px){
    .custom-button {
      width: 70% !important;
    }
  }
  @media (max-width: 767px) {
    .custom-button {
      width: 100% !important;
    }
  }

@media (max-width: 567px) {
  .form-outline {
    margin: 0 0 16px;
}
.Contact-container form label {
    font-size: 16px;
}
}