.page-container {
    background-image: url('../../assets/propose.svg');
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    height: 500px;
    position: relative;
    padding-top: 175px;
  }
  
  .content-container:after {
    content: "";
    position: absolute;
    left:0;
    top:0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.6);
    z-index: 0;
  } 
  
  .content-container {
    padding: 25px 50px;
    margin: 0 auto;
    text-align: center;
    max-width: 765px;
    height: auto;
    position: relative;
  }
  
  .content-container h1 {
    font-size: 46px;
    font-weight: bold;
    color: black; 
    position: relative;
    z-index: 1;
    margin: 0 0 4px;
  }
  
  .content-container p {
    font-size: 28px;
    font-weight: 400;
    color: black;
    position: relative;
    z-index: 1;
    margin: 0;
  }
  h2 {
    font-size: 32px;
    font-weight: 600;
  }
  p {
    font-size: 16px;
    font-weight: 400;
  }

/**** landlords ******/
  h3 {
    font-size: 18px;
    font-weight: 600;
  }
  .landlords img {
    height: 59px;
    width: auto;
  }
  .landlords .row {
    margin-left: -20px;
    margin-right: -20px;
}
  .landlords .row .col-md-4 {
    padding-left: 20px;
    padding-right: 20px!important;
}
/**** process *****/
.process-content {
  position: relative;
  display: flex;
  justify-content: space-between;
}
.process-content .vertical-line {
  height: 550px;
  background: #A0A9B2;
  width: 1px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 15px;
}
.process-content .col-left {
  width: calc(50% - 100px);
  text-align: right;
  position: relative;
  padding-left: 75px;
}
.process-content .col-right {
  width: calc(50% - 100px);
  text-align: left;
  position: relative;
  padding-right: 75px;
}
.process-content .image {
  border: 1px solid #DDDDDD;
  height: 94px;
  width: 94px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.process-content .image img {
  height: 50px;
  width: auto;
}
.process-content .text {
  width: calc(100% - 118px);
}
.process-content .box h3 {
  color: #000;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  margin: 0 0 6px;
}
.process-content .box p {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin: 0;
}
.process-content .col-left .box {
  display: flex;
  gap: 24px;
  flex-direction: row-reverse;
  align-items: center;
  position: relative;
}
.process-content .col-right .box {
  display: flex;
  gap: 24px;
  flex-direction: row;
  align-items: center;
  position: relative;
}
.process-content .col-left .box.first {
  margin-top: 100px;
  margin-bottom: 123px;
}
.process-content .hline {
  width: 100px;
  height: 1px;
  background: #DDDDDD;
  position: absolute;
  right: -100px;
  top: 50%;
  transform: translateY(-50%);
}
.process-content .col-right .hline {
  left: -100px;
  right: inherit;
}
.process-content .circle {
  height: 7px;
  width: 7px;
  border-radius: 50%;
  background: #A0A9B2;
  position: absolute;
  margin: -3px -3px;
}
.process-content .col-right .box.first {
  margin: -20px 0 123px;
}
.process-content .col-right .box.second {
  margin: 0 0 120px;
}
/**** selection *****/
.selection h3 {
  color: #000;
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  margin: 0 0 6px;
}
.selection p {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin: 0;
}
.selection .row {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}
/**** *****/
  .page2-container {
    background-image: url('../../assets/Groupe\ 1320\ 2.svg');
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    height: 400px;
    position: relative;
    padding: 60px 0;
  }
  .page2-container .container {
    position: relative;
}
  .content2-container:after {
    content: "";
    position: absolute;
    left:0;
    top:0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.6);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    border-radius: 6px;
    z-index: 0;
  } 
  .content2-container {
    padding: 34px 72px 34px 34px;
    max-width: 760px;
    width: 100%;
    height: auto;
    position: relative;
}
  .content2-container h2 {
    font-size: 32px;
    margin: 0 0 15px;
    font-weight: 600;
    color: black; 
    position: relative;
    z-index: 1;
  }
  .content2-container p {
    font-size: 16px;
    line-height: 30px;
    font-weight: 400;
    color: black;
    position: relative;
    z-index: 1;
  }
  .content2-container .button-black , .btn_proposemodal .button-black{
    background-color: #000;
    color: #fff;
    height: 40px;
    border-radius: 6px;
    border: none;
    padding: 8px 18px 9px 19px;
    z-index: 1;
    font-size: 18px;
    line-height: 20px;
    margin: 5px 0 0;
    position: relative;
    outline: 0!important;
    box-shadow: none;
    border: none!important;
}
.content2-container .button-black:hover, .content2-container .button-black:focus,
.btn_proposemodal .button-black:hover, .btn_proposemodal .button-black:focus {
  background-color: #1c1c1c;
}
.button-black .icon-envelop {
  width: 26px;
  height: 19px;
  margin-top: -4px;
  margin-right: 6px;
}
.renovation .badge {
    text-align: center;
    border-radius: 6px;
    color: white;
    padding: 9px 29px 8px;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    height: 40px;
    background-color: black !important; 
}
.renovation .image-right {
  position: relative;
  padding: 20px 25px 20px 6px;
}
.renovation .image-left {
  position: relative;
  padding: 20px 6px 20px 25px;
}
.renovation .badge.badge-left {
  position: absolute;
  bottom: 0;
  left: 0;
}
.renovation .badge.badge-right {
  position: absolute;
  top: 0;
  right: 0;
}
.renovation img {
  height: 366px!important;
  width: 100%;
  object-fit: cover;
  border-radius: 6px;
}
.renovation .col-md-6 {
  position: relative;
}
.renovation .col-md-6:first-child:after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  top: 16px;
  bottom: 0;
  height: calc(100% - 24px);
  border-right: 1px dashed #A0A9B2;
}
.process-content.mobile {
  display: none;
}
.block-btn-propose {
  position: fixed;
  right: 2%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
}
button.btn-propose {
  background: transparent;
  border: none!important;
  outline: 0!important;
  box-shadow: none!important;
  color: #fff;
  display: flex;
  align-items: center;
}
button.btn-propose span.btn-txt {
  padding: 9px 12px 9px 32px;
  color: #FFF;
  font-size: 18px;
  font-weight: 700;
  border-radius: 6px;
  background: #35CE92;
  transition: all .3s ease;
}
button.btn-propose span.btn-icon {
  width: 54px;
  height: 54px;
  padding: 9px;
  background: #35CE92;
  border-radius: 50%;
  margin-right: -29px;
  z-index: 1;
  transition: all .3s ease;
}
button.btn-propose:hover span.btn-txt , 
button.btn-propose:hover span.btn-icon {
  background: #000;
}
/****** *******/
@media(max-width:1200px){
  .content-container h1 {
    font-size: 40px;
  }
  .content-container p {
    font-size: 26px;
  }
  .page-container {
    height: 400px;
    padding-top: 140px;
}
h2 {
  font-size: 30px;
}
.landlords .row .col-md-4 {
  padding-left: 15px;
  padding-right: 15px!important;
}
.landlords .row {
  margin-left: -15px;
  margin-right: -15px;
}
.process-content .col-left {
  width: calc(50% - 50px);
  padding-left: 0;
}
.process-content .col-right {
  width: calc(50% - 50px);
  padding-right: 0;
}
.process-content .col-right .hline {
  left: -50px;
}
.process-content .hline {
  width: 50px;
}
.process-content .hline {
  right: -50px;
}
.process-content .col-left .box, .process-content .col-right .box {
  gap: 16px;
}
.process-content .image img {
  height: 42px;
}
.process-content .image {
  height: 80px;
  width: 80px;
}
.process-content .col-left .box.first {
  margin-top: 108px;
  margin-bottom: 99px;
}
.process-content .col-right .box.first {
  margin: -20px 0 80px;
}
.process-content .col-right .box.second {
  margin: 0px 0 100px;
}
.selection img {
  height: 56px;
}
.selection .row {
  margin-left: -1rem;
  margin-right: -1rem;
}
.selection .row .col-md-4 {
  padding-right: 1rem!important;
  padding-left: 1rem!important;
}
}

@media(max-width:992px){
.process-content .box h3 {
  font-size: 16px;
  line-height: 24px;
}
.process-content .hline {
  width: 10px;
}
.process-content .col-right .hline {
  left: -10px;
}
.process-content .col-right, .process-content .col-left {
  width: calc(50% - 10px);
}
.process-content .hline {
  right: -10px;
}
.process-content .vertical-line {
  top: 30px;
}
.process-content .col-right .box.second {
  margin: 0px 0 65px;
}
.process-content .col-right .box.first {
  margin: -36px 0 60px;
}
.process-content .col-left .box.first {
  margin-top: 90px;
  margin-bottom: 80px;
}
.row-line {
  flex-wrap: wrap;
}
.selection .col-img {
  width: 100%;
  padding-bottom: 1rem;
}
.renovation img {
  height: 300px!important;
}
.content-container {
  max-width: 690px;
  padding: 25px 40px;
}
}
@media (max-width: 768px) {
.page-container {
    height: auto;
    padding: 70px 0;
}
.content-container h1 {
  font-size: 28px;
  line-height: 1.3;
}
.content-container p {
  font-size: 20px;
  line-height: 1.5;
}
.content-container {
  padding: 20px 25px;
}
.landlords .col-md-4 { 
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.landlords .col-md-4 p {
  width: 100%;
}
.landlords img {
  height: 59px;
  width: auto;
  margin-right: 20px;
}
.landlords .col-md-4 h3 {
  width: calc(100% - 100px);
}
h2 {
  font-size: 24px;
}
.page2-container {
  height: auto;
  padding: 40px 0;
}
.process-content .vertical-line {
  top: 30px;
  display: none;
}
.process-content .hline {
  display: none;
}
.process-content .col-right, .process-content .col-left {
  width: 100%;
}
.process-content {
  flex-wrap: wrap;
}
.process-content.desktop {
  display: none;
}
.process-content.mobile {
  display: block;
}
.process-content .box {
  margin: 0 0 24px!important;
}
.process-content .image {
  height: 62px;
  width: 62px;
}
.process-content .image img {
  height: 34px;
}
.process-content .col-left .box, .process-content .col-right .box {
  gap: 13px;
}
.process-content .text {
  width: calc(100% - 75px);
}
.selection img {
  height: 50px;
}
.selection .col-img {
  width: 70px;
}
.selection h3 {
  font-size: 16px;
  line-height: 24px;
}
.selection p {
  font-size: 14px;
  line-height: 22px;
}
.process-content .box p {
  font-size: 14px;
  line-height: 22px;
}
p {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}
h3 {
  font-size: 16px;
}
.content2-container p {
  font-size: 14px;
  line-height: 24px;
}
.content2-container h2 {
  font-size: 24px;
  margin: 0 0 10px;
}
.content2-container .button-black {
  padding: 8px 12px 9px 12px;
  font-size: 14px;
}
.button-black .icon-envelop {
  width: 20px;
  height: 18px;
}
.renovation .col-md-6:first-child:after {
  display: none;
}
.renovation .image-left {
  position: relative;
  padding: 20px 0;
  margin: 0 0 1rem;
}
.renovation .badge.badge-left {
  left: 26px;
}
.renovation .image-right {
  padding: 20px 0;
}
.renovation .badge.badge-right {
  position: absolute;
  top: inherit;
  right: 26px;
  bottom: 0;
}
.Feedback-container h2 {
  font-size: 24px;
  font-weight: 400;
}
.renovation img {
  height: auto!important;
  min-height: 200px;
}
button.btn-propose span.btn-txt {
  padding: 6px 9px 6px 26px;
  font-size: 14px;
  font-weight: 600;
}
button.btn-propose span.btn-icon {
  width: 42px;
  height: 42px;
  padding: 7px;
  margin-right: -20px;
}
button.btn-propose span.btn-icon img {
  width: 28px;
  height: 28px;
}
.block-btn-propose {
  right: 20px;
  bottom: 40px;
  top: inherit;
  transform: none;
}
.row.before-after {
  padding-bottom: 16px;
  margin-bottom: 0px;
  border-bottom: 1px dashed #A0A9B2;
}
}