.Book-container{
    gap: 16px;
    flex-direction: column;
    padding: 2rem;
}

.Book-container>.row {
    box-shadow: none;
    border-radius: 6px;
    border: 1px solid var(--grey, #DFDFDF);
    padding: 12px 0;
}
.Book-container .payment-row {
    padding: 24px 10px 10px!important;
}
.Book-container-heading {
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
    text-align: center;
    margin: 12px 0 18px;
}
.payment-row .Book-container-heading {
    margin: 0 0 12px;
}
.Progress-paragraph p{
   font-size: 16px;
   font-weight: 400;
   line-height: 28px;
   margin: 0;
}
.Payments-steps {
    width: 100%;
    position: relative;
}
.Payments-steps span{
    font-size: 16px;
    font-weight: 600;
 }

 .Payments-steps p,
 .Fee p,
 .Fee span{
    font-size: 14px;
    font-weight: 400;
 }

 .margin-bottom-min-20{
    margin-bottom: -20px;
 }

 .margin-bottom-min-40{
    margin-bottom: -40px;
 }

 .Fee {
    color: #FF4B55;
    max-width: 200px;
 }

.progresses{
    display: flex;
    align-items: center;
}

.line{

    width: 15px;
    height: 20px;
    border-right: 2px dashed black;
    display: block;
}
span.line.line-1 {
    height: 32px;
    margin-top: -15px;
}
.line.line-2 {
    height: 32px;
    margin-top: -15px;
}   
span.line.line-3 {
    height: 40px;
    margin-top: -33px;
}
span.line.line-4 {
    height: 30px;
    margin-top: -15px;
}
.steps{
    display: flex;
    background-color: #fff;
    color: #000;
    border: 1px solid #A0A9B2;
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    width: 30px;
    height: 30px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
.steps.current, .steps.done {
    background-color: #000;
    color: #fff;
    border: 1px solid #000;
}
.Book-container .payment-row .steps {
    width: 32px;
}
.img-responsiveb {
    max-width: 100%;
    width: 100%;
    height: auto;
    border-radius: 10px;
}
@media (max-width: 600px) {
    .Payments-steps span {
        font-size: 13px;
        font-weight: 800;
    } 
    .Progress-paragraph p{
        font-size: 14px;
    }
}
