.flex-none{
    flex-wrap: nowrap;
}
.Pictures-countries {
  position: relative;
}

.infoRoom {
  position: relative;
  top: 100%; 
  left: 0; 
}

@media (max-width: 992px){
  .infoRoom {
    position: relative;
    top: 100%;
  }
}
 
@media (max-width: 808px) {
  .Pictures-countries {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center; 
    
  }
  
  .infoRoom {
    position: relative;
    margin-top: 10px; 
  
  }
  
}

@media (max-width: 576px) {
.city-item {
  width: 80%;
}
.button-container {
  margin-top: 20px!important;
}
}
@media (max-width: 480px) {
.city-item {
  width: 80%;
}
}
   