.Explore-container{
    position: relative;
}

.Explore-container h2{
    font-size: 32px;
    font-weight: 400;
}

.Explore-container p{
    font-size: 16px;
    font-weight: 400;
}

.Explore-more-countries-content h3{
    font-size: 18px;
    font-weight: 600;
    color: #000;
    margin: 0;
}

.Explore-more-countries-content p{
    font-size: 14px;
    font-weight: 400;
}

.Pictures-countries{
    position: relative;
    width: 100%; 
    text-align: center; 
}
.city-item {
    margin-right: 30px;
    
    cursor:pointer;
}

.Room-content{
    width: auto;
    justify-content: space-between;
}
.Room-content img {
    margin-right: 6px;
}
.Room-content p {
    margin: 0;
    color: #000;
    font-size: 14px;
    font-weight: 400;
}
.img-responsive {
    max-width: 100%;
    width: 100%;
    height: auto;
    border-radius:10px;
}
@media(max-width:576px){
    .img-responsive {
    max-width: 100%;
    width: 100%;
    height: auto;
    }
    .Explore-more-countries-content{
    display: flex!important;
    }
    .city-item {
        margin-right: auto;
        margin-left:auto;
    }

}
.react-multi-carousel-item {
    transform-style: preserve-3d;
    backface-visibility: hidden;
    margin: 0 0px;
}
.carousel-container button.react-multiple-carousel__arrow {
    display: none !important;
  }

  
.button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px; 
  }
  /* Styles pour un bouton désactivé */
.disabled {
    pointer-events: none; /* Désactive les événements de clic */
    opacity: 0.5; /* Rend le bouton partiellement transparent */
    cursor: not-allowed; /* Change le curseur en "non autorisé" */
  }
  
.arrow-icon {
    font-size: 20px; 
}
.custom-next-arrow, .custom-prev-arrow {
    width: 38px; 
    height: 38px; 
    margin: 0 6px; 
    cursor: pointer;
    color: #A0A9B2;
    border: 1px solid #A0A9B2;
    border-radius: 50%;
    transform-origin: center;
    line-height: 36px;
    text-align: center;
 }
 .custom-next-arrow:hover, .custom-prev-arrow:hover {
    color: black;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.16);

    border-color: #fff;
 }

  .carousel-container {
    max-width: none; 
  }
  /* for mobile screens */
  @media (max-width:1399px)and (min-width:992px){
    .img-responsive {
        /* max-width: 90%; */
        height: auto;
      }
  }
  
@media (max-width: 992px) {
    .Explore-container h2{
        font-size: 28px;
    }
}

@media (max-width: 600px) {
    .Explore-container h2{
        font-size: 26px;
        font-weight: 400;
    }

    .Explore-container p{
        font-size: 14px;
        font-weight: 400;
    }

}
  @media (max-width: 575px) {
  .carousel-container {
    margin-right: -8px;
}
  }