.breadcrumbs {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-bottom: 10px;
    
    flex-wrap: wrap;
  }
  @media (min-width:992px) {
    /* .breadcrumbs{
      margin-left: 18%;
    } */
  }
  @media (min-width:1200px) and (max-width:1841px) {
    /* .breadcrumbs{
      margin-left: 15%;
    } */
  }
  @media (max-width:1800px) {
    /* .breadcrumbs{
      margin-left: 18%;
    } */
  }
  .crumbs-container {
    display: flex;
    align-items: center;
  }
  
  .crumb {
    margin-right: 5px;
    display: flex; 
    align-items: center; 
    text-decoration: none; 
  }
  
  .crumb.active {
    font-weight: bold; 
  }
  
  .crumb-separator {
    margin: 0 5px 0 0;
    font-size: 15px;
  }
  
  .home-icon {
    margin-right: 5px;
    font-size: 20px;
    position: relative;
    margin-top: -3px;
  }
  .breadcrumb-link {
    color: black !important;
    text-decoration: none;
    font-size: 18px;
  }
  .breadcrumb-link-h {
    color: black !important;
    text-decoration: none;
    font-size: 18px;
  }
  .breadcrumb-link-h:hover {
    text-decoration: underline;
  }

  @media (max-width:576px){
    .breadcrumb-link {
      font-size: 14px;
    }
  }