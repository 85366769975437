@font-face {
    font-family: 'Source Sans 3';
    src: url('./assets/font/static/SourceSans3-Regular.ttf') format(truetype);
    font-weight: 400;
}

@font-face {
    font-family: 'Source Sans 3';
    src: url('./assets/font/static/SourceSans3-SemiBold.ttf') format(truetype);
    font-weight: 600;
}


*,
*::before,
*::after {
 padding: 0;
 margin: 0;
 box-sizing: border-box;
 scroll-behavior: smooth;
 font-family: 'Source Sans 3', sans-serif;
}

a {
    text-decoration: none;
}
