.Booking-content h2{
  font-weight: 600;
  font-size: 32px;
}

.Booking-content span {
 font-size: 18px;
 font-weight: 400;
}


.Booking-content-application h3{
 font-size: 28px;
 font-weight: 400;
}

.Input-disabled {
 outline: none;
 padding: 8px 12px;
 border: 1px solid var(--grey, #DFDFDF);
 background: #F5F5F5;
 font-size: 18px;
 font-weight: 400;
 border-radius: 6px;
 height: 40px;
 color: #000;
}
#file-upload-form .row.mb-4 {
 margin-bottom: 22px!important;
}
.form-control {
 height: 40px;
 padding: 8px 10px;
 font-size: 16px;
 font-style: normal;
 font-weight: 400;
 color: #000;
 border-radius: 6px;
 border: 1px solid var(--grey, #DFDFDF);
 background: #FFF;
}
.react-tel-input .form-control {
 height: 40px;
}
// input[type="date"]::before {
//   display: none;
// }
input[type="date"]::-webkit-calendar-picker-indicator {
 display: none;
}
.form-date {
 position: relative;
}
.form-date:after {
  content: "";
 position: absolute;
 top: 9px;
 right: 9px;
 width: 25px;
 height: 22px;
 background-image: url("../../assets/calendar.svg");
 background-color: #fff;
 background-repeat: no-repeat;
 background-position: center;
 background-size: 25px;
 z-index: 9;
}

.input-datepicker {
  position: relative;
  width: 100%;
}
.input-datepicker .form-control {
  position: relative;
  width: 100%!important;
  background: #fff;
}
.input-datepicker .form-control::placeholder, .input-datepicker .form-control::-moz-placeholder, .input-datepicker .form-control::-webkit-input-placeholder {
  color: #000;
} 
.form-control::placeholder {
  color: #000;
}
.react-tel-input .flag-dropdown {
 background-color: #fff;
 border: 1px solid var(--grey, #DFDFDF);
 border-right: none;
 border-radius: 3px 0 0 3px;
}
.Booking-content form label,
.Booking-content-heading {
  font-size: 18px;
  font-weight: 600;
}
.Booking-content label.file-label span {
 font-size: 18px;
 font-weight: 600;
}
.thinking-container{
 margin-bottom: 80px;
}

.thinking-container span{
font-size: 16px;
font-weight: 400;
}

// upload

.input-section {
 display: flex;
 flex-direction: column;
}

.input-container {
 padding: 20px 20px 22px;
 border-radius: 6px;
 border: 1px dashed #000;
 background: #FFF;
 position: relative;
 text-align: center;
}

.sub-inputs {
 display: flex;
}

.uploaded-image {
 max-width: 100%;
 height: auto;
 margin-top: 10px;
}

.uploaded-pdf {
 margin-top: 10px;
 font-size: 14px;
}


.input-container input[type="file"] {
 opacity: 0;
 position: absolute;
 top: 0;
 height: 100%;
 width: 100%;
 left: 0;
}

p.uploaded-pdf {
 border-radius: 6px;
 border: 1px solid var(--grey, #DFDFDF);
 background: #FFF;
 padding: 9px 14px;
 color: #000;
 font-size: 14px;
 font-weight: 400;
 position: relative;
}
.uploaded-pdf .close-file {
 position: absolute;
 right: 8px;
 top: 8px;
 cursor: pointer;
}

.sub-inputs.row {
 margin-left: -5px!important;
 margin-right: -5px!important;
}
.sub-inputs.row .col-sm-4 {
 padding-left: 5px!important;
 padding-right: 5px!important;
}
.sub-inputs .input-container {
 height: 130px;
 padding: 14px 27px 12px; 
 display: flex;
 justify-content: center;
 align-items: center;
}

.Booking-content .input-container label.file-label span {
 font-size: 16px;
 font-weight: 400;
 line-height: initial;
 color: #000; 
 display: block;
}
.submit-button {
 margin: 12px 0 24px;
 border-radius: 6px;
 background: #000;
 border: 1px solid #000!important;
 color: #FFF;
 font-size: 18px;
 font-weight: 400;
 padding: 8px 82px;
 transition: all .4s ease;
}

.submit-button:hover, .submit-button:focus {
 background: #fff;
 color: #000;
}
.hidden{
 display: none;
}
.thank{
  display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: stretch;
    justify-content: flex-start;
    align-items: center;
}
.thank p{
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
}
@media(max-width:576px){
  .sub-inputs .input-container {
    margin: 16px 0 0 0;
  }
  .submit-button {
    width: 100%;
  }
}