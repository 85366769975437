.alert {
    padding: 20px 0;
    margin: 0 auto;
}
.alert h2 {
   font-weight: 600;
   font-size: 20px; 
   margin: 0 0 10px;  
}
.my-container {
    align-content: flex-start;
    flex-wrap: wrap;
}
.alert button {
    outline: none;
    border: none;
    font-size: 16px;
    color: white;
    border-radius: 6px;
    background-color: black;
}