.popup.popup-msg-alert {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 10;
  overflow-y: auto;
}
.popup-msg-alert .popup-content {
  background: white;
  padding: 30px 20px 30px;
  border-radius: 5px;
  text-align: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 420px;
  width: 100%;
  margin: 24px;
}
.popup-msg-alert .popup-body {
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow-y: auto;
}
.popup-msg-alert .close-btn {
  min-width: 130px;
  border: 2px solid #000!important;
  border-radius: 6px;
  background: #000!important;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  padding: 4px 12px;
  box-shadow: none!important;
  outline: 0!important;
  display: table;
  margin: 0 auto;
  transition: all .3s ease;
}
.popup-msg-alert .close-btn:hover, .popup-msg-alert .close-btn:focus {
  color: #000;
  background: #fff!important;
}
.popup-msg-alert .icon-msg {
  font-size: 42px;
  color: #000;
  line-height: 42px;
}
.popup-msg-alert .message {
  margin: 21px 0 21px;
  font-size: 22px;
  font-weight: 600;
  color: #000;
  line-height: 30px;
}
  