.NavBar-container {
    align-items: center;
    justify-content: space-between;
    height:84px;
    width: 100%;
    background-color: white;
    margin-bottom: 0;

}

.NavBar-container img {
  cursor: pointer;
}


.List-container {
    display: flex;
    align-items: center;
    margin: 0;
}

.List-items {
    list-style: none;
    margin-left: 30px;
    padding: 8px 28px 7px;
    color: black;
    font-size: 18px;
    font-weight: 400;
    cursor: pointer;
}

.List-items:hover{
  color: black
}


.List-items.active {
  background-color: black;
  border-radius: 6px;
  color: white;
}


.menu-icon {
  display: none;
}
 
@media (max-width: 990px) {
  .NavBar-container {
    padding: 0 25px;
    height: 80px;
  }

  .List-items {
    display: block;
    padding: 25px;
    margin:25px;
  }

  .menu-icon {
    display: block;
    cursor: pointer;
    width: 40px;
   
    color: black;
   
      }
      .menu-icon img{
        width: 40px;
        height: 40px;
       
    
      }

  .nav-elements {
    position: absolute;
    right: 0;
    top: 61px;
    background-color: white;
    text-align: left;
    height: 100vh;
    transition: all 0.3s ease-in;
    overflow:  scroll;
    z-index: 999;
    width: 0;
    display: block;
}

  

  .nav-elements.active {
    width: 100%;
  }
}
/* @media (max-width: 575px) {
  .NavBar-container {
    padding: 0;
    display: flex;
    justify-content: space-around;
  }

  .List-items {
    display: block;
  } 

  .menu-icon {
        display: block;
        cursor: pointer;
        width: 60px;
        height: 60px;
        color: black;
        position: relative;
      }

  .menu-icon img{
    width: 50%;
    margin-top: 20px;
    margin-left: 50px;
  }
  

  .nav-elements {
    position: absolute;
    right: 0;
    top: 92px;
    background-color: white;
    text-align: left;  
    height: 100vh;
    transition: all 0.3s ease-in;
    overflow: hidden;

    width: 0;
    display: block;


  }

  .nav-elements.active {
    width: 100%;
  }
  
} */

.nav-divider {
  width: 100%;
    height: 1.5px;
    border: 1px;
    background-color: #DEDEDE;
    /* margin-bottom: 20px; */
    /* margin-top: 15px; */
}

.nav-divider.hide-on-home {
  display: none;
}

 @media (max-width: 991px) {
.List-items {
  padding: 13px 14px 13px 34px;
  margin: 0;
  border-bottom: 1px solid #DFDFDF;
  border-radius: 0!important;
  border-left: 7px solid transparent;
}
.nav-elements {
  padding-top: 45px;
}
.List-items.active {
  background-color: transparent;
  border-radius: 0!important;
  color: #000;
  border-left-color: black;
}
.menu-icon.false img.close-nav {
  display: none;
}
.menu-icon.false img.open-nav {
  display: block;
}
.menu-icon.active img.close-nav {
  display: block;
}
.menu-icon.active img.open-nav {
  display: none;
}
.menu-icon img.close-nav {
  width: 30px;
  text-align: center;
}
.nav-list {
  max-width: 690px;
  display: table;
  margin: 0 auto;
  width: 100%;
}
}
@media (max-width: 768px) {
.menu-icon img {
  width: 32px;
  height: 32px;
}
.menu-icon img.close-nav {
  width: 24px;
}
.nav-list {
  max-width: 510px;
  display: table;
  margin: 0 auto;
  width: 100%;
}
}