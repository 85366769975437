.Intro-container{
    
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    height: 600px;
}

.Content-container{
  padding: 30px 50px 40px;
  margin: 110px auto;
  border-radius: 4px;
  display: block; 
  max-width: 900px;
  width: 90%;
  text-align: center;
  position: relative;
}
.input-date:after {
  z-index: 0;
}
.react-datepicker-wrapper {
  width: 100%;
}
.input-datepicker {
  position: relative;
  width: 100%;
}
.react-datepicker-popper{
  z-index:2;
}
button.react-datepicker__navigation {
  align-items: center;
  background: none;
  display: flex;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 2px;
  padding: 0;
  border: none;
  z-index: 2;
  height: 32px;
  width: 32px;
  text-indent: -999em;
  overflow: hidden;
}
.input-datepicker .form-control {
  position: relative;
  width: 100%!important;
  background: #fff;
}
.input-datepicker .form-control::placeholder, .input-datepicker .form-control::-moz-placeholder, .input-datepicker .form-control::-webkit-input-placeholder {
  color: #000;
} 
.form-control::placeholder {
  color: #000;
}
.react-datepicker__navigation .react-datepicker__navigation--next{
  z-index: 2;
}
.Content-container:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.40);
  z-index: 0;
}

.Content-container h2, .Content-container h1, .Content-container div {
  position: relative;
  z-index: 1;
}

.Content-container h1 {
    font-size: 26px;
    font-weight: 400;
}

.Content-container h2,
.Content-container h1{
    color: white;
    text-align:center ;
}

.Content-container h2{
  font-size: 46px;
  font-weight: 600;
  margin: 0 0 1.25rem;
}

.Input-container{
    padding: 20px;
    border-radius: 6px;
    background: #FFF;
}
.List-select{
    position: relative; 
}
.form-date {
  position: relative;
}
.Select-country-container,
.Movie-date{ 
    padding: 7px;
    border-radius: 7px;
    border-width: 1px;
    line-height: 1;
}

input[type="date"]::before { 
  content: attr(data-placeholder);
  width: 100%;
}

input[type="date"]:focus::before,
input[type="date"]:valid::before { display: none }

.Search-btn {
  outline: none;
  border: none;
  padding: 8px;
  color: white;
  background-color: black;
  border-radius: 6px;
  font-size: 18px;
  font-weight: 600;
  height: 40px;
  width: 20%;
}
.input-group {
  flex: 0 0 auto;
  width: 38%;
  position: relative;
}
.Select-country-container {
  padding: 8px 10px!important;
  height: 40px;
  color: #000;
  font-size: 16px;
  font-weight: 400;border-radius: 6px;
  border: 1px solid #DDD;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.input-date:after {
  position: absolute;
  content: "";
  top: 9px;
  right: 9px;
  width: 25px;
  height: 22px;
  background-image: url("../../../assets/calendar.svg");
  background-color: #FFF;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 25px;
  z-index: -1;
}
.input-country:after {
  position: absolute;
  content: "";
  top: 16px;
  right: 9px;
  width: 13px;
  height: 8px;
  background-image: url("../../../assets/select-bottom.svg");
  background-color: #FFF;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 13px;
  z-index: -1;
}
.Select-country-container::placeholder {
  color: #000;
}
  /* for mobile screens */
  @media (max-width: 991px) {
    .Content-container {
      padding: 20px 15px;
      margin: 0 auto;
      max-width: 690px;
      width: 100%;
  }
  .Intro-container {
    height: auto;
}
    .Content-container h1 {
      font-size: 20px;
      font-weight: 400;
    }
    .Input-container{
      flex-direction: column;
      padding: 0;
      background: transparent;
  }

    .Content-container h2 {
      font-size: 26px;
      font-weight: 600;
    }

    .Select-country-container, .Movie-date {
      padding: 10px 0;
      margin: 5px 0;
    }

    .Intro-container{
      background-position: right;
    }

    .Search-btn{
      width: 100%;
    }
    .Input-container .input-group {
      width: 100%;
      background: #fff;
      border-radius: 6px;
      margin: 0 0 12px;
  }
  .Select-country-container {
    margin: 0!important;
}
  }

   /* for mobile screens */
@media (max-width: 767px) {
  .Content-container {
    max-width: 510px;
    margin: 0.5rem auto;
  }
  .Content-container h1 {
    font-size: 20px;
    line-height: 28px; 
  }
  .Select-country-container {
    font-size: 16px; 
  }
}