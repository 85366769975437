.Apartements .h3,
.Useful-links .h3,
.Create-alert .h3 {
    font-size: 18px;
    text-align: start;
    font-weight: 600;
    margin: 0 0 18px;
    color: #000;
}

.Apartements p, .Useful-links p,
.Apartements p a , .Useful-links p a {
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  line-height: 24px;
  color: #000;
}
.Apartements p, .Useful-links p {
  margin: 0 0 12px;
}
.Apartements p a, .Useful-links p a {
  border-bottom: 1px solid transparent;
  color: #000;
  transition: all .3s linear;
}
.Apartements p a:hover, .Useful-links p a:hover {
  border-bottom: 1px solid #000;
}
.btn-alert {
  outline: none;
  border: none;
  color: white!important;
  padding: 9px 24px;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 400;
  background: #000;
  line-height: 22px;
  box-shadow: none;
  transition: all .4s ease;
}
.btn-alert:hover {
  color: #fff;
  box-shadow: inset 0px 2px 8px 2px rgba(255, 255, 255, .5);
}
.btn-alert img {
  margin-right: 10px;
}

.Copyright {
  text-align: center;
  padding: 12px 0;
  font-size: 16px;
  font-weight: 400;
  border-top: 1px solid #A0A9B2;
}
.Copyright p {
  font-size: 16px;
  font-weight: 400;
  color: #000;
  line-height: 24px;
}

.Footer-container-mobile{
    display: none;
}

.Footer-container{
    display: flex;
}

.Apartements {
  flex: 0 0 auto;
    width: 33%;
}
.Useful-links {
  flex: 0 0 auto;
  width: 26%;
}
.Create-alert {
  flex: 0 0 auto;
  width: 26%;
}
@media (max-width: 992px) {
  .Footer-container-mobile {
    display: block;
  }
  .Footer-container-mobile {
    padding: 56px 0 35px;
}
  .Footer-container{
    display: none;
  }
  .Apartements, .Useful-links, .Create-alert {
    width: 60%!important;
  }
  .Apartements .h3, .Useful-links .h3, .Create-alert .h3 {
    margin: 0 0 12px;
  }

}

@media (max-width: 767px) {
  
  .Apartements, .Useful-links, .Create-alert {
    width: 100%!important;
  }
  .Footer-container .Faq-container h2 {
    font-size: 24px;
  }
  .Footer-container .Faq-container p {
    font-size: 16px!important;
  }
  .Footer-container .Faq-container {
    padding: 20px 0 41px;
  }
  .Footer-container .Faq-btn {
    font-size: 16px;
    padding: 12px 16px 11px 16px;
    width: 200px;
    height: 46px;
  }
  .Footer-container .Faq-btn img {
    width: 20px;
    margin-left: 10px;
}
.Footer-container-mobile {
  padding: 42px 0 30px;
}
.Copyright {
  padding: 9px 0;
}
.Apartements .h3, .Useful-links .h3, .Create-alert .h3 {
  font-size: 16px;
}
.Apartements p, .Useful-links p {
  margin: 0 0 8px;
}
.Apartements p, .Useful-links p, .Apartements p a, .Useful-links p a {
  font-size: 14px;
}
}
@media (max-width: 600px) {
  .Faq-container {
    padding: 24px 0 36px;
  }
  .Faq-btn img {
    width: 20px;
    margin-left: 10px;
}
  .Faq-btn {
    left: inherit;
    transform: none;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    padding: 12px 16px 12px 18px;
    transition: all .4s ease;
    display: flex;
    align-items: center;
    width: auto;
    margin: 0 auto;
  }
  .Faq-container p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}
}
