.Feedback-container h2 {
    font-size: 32px;
    font-weight: 400;
}

.feedback-icons-content span{
    font-size: 18px;
    font-weight: 600;
}

.feedback-icons-content p {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
}
.button-containerfeed {
    display: flex;
    justify-content: center;
    
  }


/* for mobile screens */
@media (max-width: 576px) {
    .Feedback-container h2{
        font-size: 24px;
        font-weight: 400;
    }
    .feedback-icons-content {
        margin: -5px 0 0;
    }
    .Feedback-container .feedback-icons-li img {
        max-height: 90px;
        width: auto;
    }
    .feedback-icons-content span {
        font-size: 16px;
        font-weight: 600;
    }
    .feedback-icons-content p {
        font-size: 14px;
        font-weight: 400;
        margin: 0;
    }
}