
.Reason-to-rent-container h2{
    font-size: 32px;
    font-weight: 400;
    margin: 0 0 10px;
}

.Reason-to-rent-container p{
    font-size: 16px;
    font-weight: 400;
    margin: 0 0 40px;
}

.reasons-icons-item {
    margin: 0 0 50px;
}

.Reason-to-rent-container .reasons-icons-item p {
    margin: 0;
    line-height: 22px;
}
.reasons-icons-item h3 {
    font-size: 18px;
    font-weight: 600;
    margin: 0 0 4px;
}
.reasons-icons-item img {
    height: 80px;
    width: auto;
}
/* for mobile screens */

@media (max-width: 992px) {
    .reasons-icons-item {
        margin: 0 0 1.5rem;
    }
    .reasons-icons-item img {
        height: 65px;
        width: auto;
    }
    .Reasons-icons-container {
        margin-top: -2px;
    }
    .Reason-to-rent-container h2 {
        font-size: 28px;
    }
}
@media (max-width: 600px) {

    .Reason-to-rent-container h2{
        font-size: 24px;
        font-weight: 400;
    }

    .Reason-to-rent-container p{
        font-size: 14px;
        font-weight: 400;
        margin: 0 0 24px;
    }

    .Reasons-icons-li img{
        width: 52px;
    }
    .reasons-icons-item img {
        height: 52px;
        width: auto;
    }
    .reasons-icons-item h3 {
        font-size: 16px;
    }

}
