.Faq-container {
    background-color: #383838;
    color: white;
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    align-items: center !important;
    padding: 32px 0 52px;
    position: relative;
}

.Faq-container h2 {
    font-size: 32px;
    font-weight: 600;
    margin: 0 0 10px;
}

.Faq-container p {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    z-index:1;
    margin: 0;
}

.Faq-btn {
    position: absolute;
    top: calc(100% - 25px);
    left: 50%;
    transform: translateX(-50%);
    border-radius: 6px;
    background: #FFF;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    height: 50px;
    outline: none;
    border: none;
    z-index: 2;
    color: black;
    background-color: white;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    padding: 14px 18px 13px 20px;
    transition: all .4s ease;
}
.Faq-btn:hover {
    box-shadow: inset 0px 2px 8px 2px rgba(0, 0, 0, .3);
}
.Faq-btn img {
    width: 24px;
    margin-left: 16px;
}

@media (max-width:992px) {
    .Faq-container h2 {
        font-size: 28px;
    }
.Faq-container {
    padding: 24px 0 42px;
}

}
  @media (max-width:600px) {
    .Faq-container h2 {
        font-size: 25px;
    }
    
    .Faq-container p {
        font-size: 12px;
    }

    /* .Faq-btn{
        left: 20%
    } */

    .Faq-container{
        padding: 20px;
    }
  }
